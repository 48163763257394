@font-face {
  font-family: 'Gotham';
  src: url('gotham/Gotham-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('gotham/Gotham-Medium.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'The Serif';
  src: url('theSerif/TheSerifLight-Plain.eot?v202001');
  src: url('theSerif/TheSerifLight-Plain.eot?v202001') format('embedded-opentype'),
  url('theSerif/TheSerifLight-Plain.woff?v202001') format('woff'),
  url('theSerif/TheSerifLight-Plain.ttf?v202001') format('truetype'),
  url('theSerif/TheSerifLight-Plain.svg#TheSerifLight-Plain?v202001') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'The Serif';
  src: url('theSerif/TheSerifBold-Plain.eot?v202001');
  src: url('theSerif/TheSerifBold-Plain.eot?v202001') format('embedded-opentype'),
  url('theSerif/TheSerifBold-Plain.woff?v202001') format('woff'),
  url('theSerif/TheSerifBold-Plain.ttf?v202001') format('truetype'),
  url('theSerif/TheSerifBold-Plain.svg#TheSerifBold-Plain?v202001') format('svg');
  font-weight: bold;
  font-style: normal;
}
