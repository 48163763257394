@layer components {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    border: 1px solid transparent;
    height: 45px;
  }

  .cta {
    @extend .btn;
    color: theme('colors.white');
    background-color: theme('colors.primary');

    @media (hover) {
      &:hover {
        background-color: theme('colors.primaryLight');
      }
    }

    &:active {
      background-color: theme('colors.primary');
    }
  }

  .cta-dark {
    @extend .cta;
    color: theme('colors.fontColor');
    background-color: theme('colors.white');

    @media (hover) {
      &:hover {
        background-color: theme('colors.white');
        color: theme('colors.linkHover');
      }
    }

    &:active {
      color: theme('colors.fontColor');
      background-color: theme('colors.white');
    }
  }

  .primary {
    @extend .btn;
    color: theme('colors.fontColor');
    background-color: theme('colors.white');
    border-color: theme('colors.primary') !important;

    @media (hover) {
      &:hover {
        color: theme('colors.white');
        background-color: theme('colors.primaryLight');
      }
    }

    &:active {
      color: theme('colors.white');
      background-color: theme('colors.primary');
      border-color: theme('colors.primary') !important;
    }
  }

  .primary-dark {
    @extend .primary;
    color: theme('colors.white');
    background-color: theme('colors.primary');
    border-color: theme('colors.white') !important;

    @media (hover) {
      &:hover {
        background-color: theme('colors.white');
        color: theme('colors.linkHover');
      }
    }

    &:active {
      color: theme('colors.fontColor');
      background-color: theme('colors.white');
      border-color: transparent !important;
    }
  }

  .secondary {
    @extend .btn;
    color: theme('colors.fontColor');
    background-color: theme('colors.white');

    .btn-secondary-text {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: theme('colors.primary');
        transition: all 200ms ease-in-out;
      }
    }

    .btn-secondary-icon {
      color: inherit;
      transition: all 200ms ease-in-out;
    }

    @media (hover) {
      &:hover {
        color: theme('colors.primaryLight');

        .btn-secondary-icon {
          color: theme('colors.btnIconHover');
        }

        .btn-secondary-text:after {
          background-color: theme('colors.btnIconHover');
        }
      }
    }

    &:active {
      color: theme('colors.fontColor');

      .btn-secondary-icon {
        color: theme('colors.fontColor');
      }
    }
  }

  .secondary-dark {
    @extend .secondary;
    color: theme('colors.white');
    background-color: theme('colors.primary');

    .btn-secondary-text {
      &:after {
        background-color: theme('colors.white');
      }
    }

    @media (hover) {
      &:hover {
        color: theme('colors.white');

        .btn-secondary-text:after {
          background-color: theme('colors.btnIconHover');
        }
      }
    }

    &:active {
      color: theme('colors.white');

      .btn-secondary-text:after {
        background-color: theme('colors.white');
      }

      .btn-secondary-icon {
        color: theme('colors.white');
      }
    }
  }
}
