@import 'style-guide/buttons';
@import 'style-guide/typography';
@import 'form/input';
@import 'fonts/fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply p-0;
  /* For tailwind Dialog styling override */
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  @extend .text-base;

  &.is-en-germany {
    font-family: theme('fontFamily.gothamSans');
  }
}

.is-checkout {
  background-color: theme('colors.inputBackground');
}

.custom-icon {
  path {
    fill: currentColor;
    stroke: currentColor;
  }
}

/* This style corresponds to
 * the footer component due to
 * a bug in TailwindCSS not
 * recognizing template columns
 * with more than 4 columns.
 * see components/commercetools-ui/footer/index.tsx
 * This is a hardcoded and messy
 * workaround.
 */
@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

@layer base {
  html {
    font-family: 'Open Sans';
  }
}

@layer utilities {
  .relative-width {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fixed-screen-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}
